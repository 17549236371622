'use client'

import React, { useEffect, useState } from 'react'
import DivGroup from '@/src/components/OptionGroup'
import OptionGroup from '@/src/components/OptionGroup'
import CustomHead from '@/src/components/CustomHead'
import Toast from '@/src/components/Toast'
import SideBar from '@/src/components/SideBar'
import '../../../style/loading.css'
import '../../../style/translate.css'
import TopButton from '@/src/components/TopButton'
// import { useRecoilState } from 'recoil'
import SlideOptionGroup from '@/src/components/SlideOptionGroup'
import { API_URLS } from '@/src/constants/apis'

const Home = () => {
  const [isFinished, setIsFinished] = useState(false)
  const [loading, setLoading] = useState(false)
  const [content, setContent] = useState('')

  const [optionValues, setOptionValues] = useState({
    temperature: '0.2',
    maxOutputToken: '1024',
    topK: '40',
    topP: '0.95',
  })
  const [optionRanges, setOptionRanges] = useState({
    temperature: { min: '0.0', max: '1.0' },
    maxOutputToken: { min: '0', max: '1024' },
    topK: { min: '1', max: '40' },
    topP: { min: '0.0', max: '1.0' },
  })
  const [translated, setTranslated] = useState('')
  const [summarized, setSummarized] = useState('')

  const handleOptionChange = (optionName, value) => {
    setOptionValues((prevValues) => ({
      ...prevValues,
      [optionName]: value,
    }))
  }
  const handleTextareaChange = (event) => {
    setContent(event.target.value)
  }
  const handleExecButtonClick = async () => {
    try {
      if (content === '') {
        return false
      }

      const numTemperature = parseFloat(optionValues.temperature)
      if (
        isNaN(numTemperature) ||
        numTemperature < parseFloat(optionRanges.temperature.min) ||
        numTemperature > parseFloat(optionRanges.temperature.max)
      ) {
        Toast({
          message: '원본 유사도 값은 0.1 ~ 1.0 사이의 값을 입력하세요.',
          autoClose: 2000,
        })
        setOptionValues((prevValues) => ({
          ...prevValues,
          ['temperature']: '0.2',
        }))
        return false
      }

      const numMaxOutputToken = parseInt(optionValues.maxOutputToken)
      if (
        isNaN(numMaxOutputToken) ||
        numMaxOutputToken < parseInt(optionRanges.maxOutputToken.min) ||
        numMaxOutputToken > parseInt(optionRanges.maxOutputToken.max)
      ) {
        Toast({
          message: '최대 토큰 수는 0 ~ 1024 사이의 값을 입력하세요.',
          autoClose: 2000,
        })
        setOptionValues((prevValues) => ({
          ...prevValues,
          ['maxOutputToken']: '1024',
        }))
        return false
      }

      const numTopK = parseInt(optionValues.topK)
      if (
        isNaN(numTopK) ||
        numTopK < parseInt(optionRanges.topK.min) ||
        numTopK > parseInt(optionRanges.topK.max)
      ) {
        Toast({
          message: 'Top K 값은 1 ~ 40 사이의 값을 입력하세요.',
          autoClose: 2000,
        })
        setOptionValues((prevValues) => ({
          ...prevValues,
          ['topK']: '40',
        }))
        return false
      }

      const numTopP = parseFloat(optionValues.topP)
      if (
        isNaN(numTopP) ||
        numTopP < parseFloat(optionRanges.topP.min) ||
        numTopP > parseFloat(optionRanges.topP.max)
      ) {
        Toast({
          message: 'Top P 값은 0.0 ~ 1.0  사이의 값을 입력하세요.',
          autoClose: 2000,
        })
        setOptionValues((prevValues) => ({
          ...prevValues,
          ['topP']: '0.2',
        }))
        return false
      }

      setLoading(true)

      const response = await fetch(API_URLS.MODEL_VERTEXAI_PARM2_ENGLISH, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          content: content,
          temperature: optionValues.temperature,
          maxOutputToken: optionValues.maxOutputToken,
          topK: optionValues.topK,
          topP: optionValues.topP,
        }),
      })

      const data = await response.json()
      setTranslated(data.rsltContentTranslated)
      setSummarized(data.rsltContentSummarized)
      setIsFinished(true)
      console.log('response', data)
      return data
    } catch (error) {
      console.error('Error calling server API:', error)
      return null
    } finally {
      setLoading(false)
    }
  }

  const restorePage = () => {
    setIsFinished(false)
  }

  const copyToClipboard = (pIdText) => {
    const textToCopy = document.getElementById(pIdText).innerText

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        Toast({
          message: '텍스트가 클립보드에 복사되었습니다.',
          autoClose: 1500,
        })
        console.log('텍스트가 성공적으로 클립보드에 복사되었습니다.')
      })
      .catch((err) => {
        console.error('클립보드에 복사하는 도중 오류가 발생했습니다:', err)
      })
  }

  return (
    <div id="wrapper">
      <CustomHead
        title="영문 기사 요약 및 번역 모델"
        description="영문 기사 요약 및 번역 모델"
      />
      {loading && (
        <div className="loading-overlay">
          <p>기사를 생성 중입니다.<br/>잠시만 기다려주세요.</p>
          <div className="loading-spinner"></div>
        </div>
      )}
      <SideBar />
      <main id="container">
        {/*header*/}
        <div className="header lg_hide">
          <strong>JoongAng AI</strong>
        </div>
        <h1>영문 기사 요약·번역</h1>
        {/*//header*/}
        <hr />
        <section className="contents">
          {/*<!-- 개발이슈 : 실행되면 active 클래스 추가해주세요. -->*/}
          <form>
            <div className={`chain_area ${isFinished ? 'active' : ''}`}>
              <header className="title_area">
                <strong className="title">기사 작성</strong>
              </header>
              <textarea
                aria-label="원본 텍스트"
                className="form_control"
                name="inputArticle"
                placeholder="내용을 입력해 주세요"
                onChange={handleTextareaChange}
                value={content}
              ></textarea>
            </div>
            {/*<!-- 개발이슈 : 첫 화면에서는 chain_area 영역 hide 또는 영역 자체 삭제, 요약번역하기 버튼 클릭시 영역 추가 또는 show 클래스로 변환 -->*/}
            {!isFinished && (
              <SlideOptionGroup
                contentLength={content.length}
                loading={loading}
                handleButtonClick={handleExecButtonClick}
                optionValues={optionValues}
                optionRanges={optionRanges}
                onOptionChange={handleOptionChange}
              />
            )}
          </form>
        </section>
        {/*}<!-- 개발이슈 : 첫 화면에서는 chain_area 영역 hide 또는 영역 자체 삭제, 요약번역하기 버튼 클릭시 영역 추가 또는 show 클래스로 변환 -->*/}
        {isFinished && (
          <section className="contents">
            <div className="chain_area ">
              <header className="title_area">
                <strong className="title">영문 요약문</strong>
              </header>
              <div className="form_control">
                <p id="textSummarized">{summarized}</p>
                <button
                  type="button"
                  className="btn"
                  onClick={() => copyToClipboard('textSummarized')}
                >
                  복사<span className="sm_hide">하기</span>
                </button>
              </div>
            </div>
            <div className="chain_area">
              <header className="title_area">
                <strong className="title">
                  영문 요약문에 대한 한국어 번역
                </strong>
              </header>
              <div className="form_control">
                <p id="textTranslated">{translated}</p>
                <button
                  type="button"
                  className="btn"
                  onClick={() => copyToClipboard('textTranslated')}
                >
                  복사<span className="sm_hide">하기</span>
                </button>
              </div>
            </div>
            <button
              type="button"
              className="btn_l active"
              onClick={restorePage}
            >
              뒤로가기
            </button>
          </section>
        )}
      </main>
      <div
        className="loading-overlay"
        style={{ display: loading ? 'block' : 'none' }}
      >
        <p>기사를 생성 중입니다.<br/>잠시만 기다려주세요.</p>
        <div className="loading-spinner"></div>
      </div>
      <TopButton />
    </div>
  )
}

export default Home
